import React from "react"

const TrialSection = () => {
  const sectionStyle = {
    background: "linear-gradient(45deg, #f8f9fa, #ffffff)",
    padding: "60px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "400px",
    position: "relative",
    overflow: "hidden",
    "@media (max-width: 768px)": {
      padding: "40px 15px",
      flexDirection: "column",
      textAlign: "center",
    },
  }

  const containerStyle = {
    maxWidth: "1200px",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    gap: "50px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "30px",
    },
  }

  const imageContainerStyle = {
    flex: "1",
    position: "relative",
    "@media (max-width: 768px)": {
      width: "100%",
      maxWidth: "300px",
    },
  }

  const imageStyle = {
    width: "100%",
    maxWidth: "400px",
    height: "auto",
    borderRadius: "20px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.1)",
    transition: "transform 0.3s ease",
    ":hover": {
      transform: "translateY(-5px)",
    },
  }

  const contentStyle = {
    flex: "1",
    padding: "20px",
    "@media (max-width: 768px)": {
      padding: "10px",
    },
  }

  const headingStyle = {
    fontSize: "2.5rem",
    color: "#333",
    marginBottom: "20px",
    fontWeight: "bold",
    "@media (max-width: 768px)": {
      fontSize: "2rem",
    },
  }

  const subheadingStyle = {
    fontSize: "1.2rem",
    color: "#666",
    marginBottom: "30px",
    lineHeight: "1.6",
    "@media (max-width: 768px)": {
      fontSize: "1rem",
    },
  }

  const buttonStyle = {
    display: "inline-block",
    padding: "15px 30px",
    backgroundColor: "#e07845",
    color: "white",
    textDecoration: "none",
    borderRadius: "8px",
    fontSize: "1.1rem",
    fontWeight: "bold",
    transition: "all 0.3s ease",
    cursor: "pointer",
    border: "none",
    boxShadow: "0 4px 15px rgba(224,120,69,0.2)",
    ":hover": {
      backgroundColor: "#c9643a",
      transform: "translateY(-2px)",
      boxShadow: "0 6px 20px rgba(224,120,69,0.3)",
    },
  }

  const openCalendly = () => {
    window.Calendly.initPopupWidget({
      url: "https://calendly.com/codingchamps/",
    })
  }

  return (
    <section style={sectionStyle}>
      <div className="banner_w3lspvt position-relative" style={containerStyle}>
        <div style={imageContainerStyle}>
          <img
            src="images/mom-with-kid.jpg" // Replace with your image path
            alt="Happy kid learning to code"
            style={imageStyle}
          />
        </div>
        <div style={contentStyle}>
          <h3
            className="tittle font-weight-bold"
            data-sal="slide-up"
            data-sal-delay="200"
            data-sal-duration="1500"
            data-sal-easing="ease-out-back"
          >
            Start Your Child's Coding Journey{" "}
            <span className="title-span"> Today!</span>
          </h3>
          <p className="text-sty-banner">
            Book a free trial class and watch your child discover the exciting
            world of coding. Our expert instructors make learning fun and
            engaging!
          </p>
          <button
            onClick={openCalendly}
            className="btn button-orange read-more text-white"
            onMouseOver={e => (e.target.style.backgroundColor = "#c9643a")}
            onMouseOut={e => (e.target.style.backgroundColor = "#e07845")}
          >
            Book Your Free Trial Class
          </button>
        </div>
        <div className="icon-effects-w3l">
          <img
            src="images/shape1.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-one"
          />
          <img
            src="images/shape2.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-two"
          />
          <img
            src="images/shape3.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-three"
          />
          <img
            src="images/shape5.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-four"
          />
          <img
            src="images/shape4.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-five"
          />
          <img
            src="images/shape6.png"
            alt=""
            className="img-fluid shape-wthree shape-w3-six"
          />
        </div>
      </div>
    </section>
  )
}

export default TrialSection
